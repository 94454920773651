.tabbar{
    background-color: white;
    border-top: gray solid 1px;
    position: fixed;
    display: flex;
    flex-direction: column;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .tabbarContent{
    height: 64px;
    width: 100%;
    display: flex;
    justify-items: center;
    align-items: center;
  }

.item{
  height: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.item img{
  height: 25px;
  width: 25px;
}

.item p{
  color: gray;
  font-size: 13px;
  font-weight: 500;
  display: block;
  text-align: center;
  margin: 0;
  padding: 0;
  margin-top: 5px;
  z-index: 100;
}
.safeaire{
    height: constant(safe-area-inset-bottom);
    height: env(safe-area-inset-bottom); 
    width: 100%;
    display: flex;
    background-color: white;
}